<template>
    <div class="k-flex-col">
        <div class="k-flex-row">
            <div class="flex-grow-1 mr-4">
                <b-input-group>
                    <template #prepend>
                        <b-input-group-text>
                            <b-icon-search />
                        </b-input-group-text>
                    </template>
                    <b-form-input
                        placeholder="Rechercher une génération"
                        v-model="search"
                        debounce="500"
                    />
                </b-input-group>
            </div>

            <div class="d-flex flex-column">
                <small class="text-kalkin-1 text-center w-100">
                    Génération par page
                </small>
                <b-form-group>
                    <b-form-radio-group
                        v-model="perPage"
                        size="sm"
                        button-variant="outline-kalkin-1"
                        buttons
                    >
                        <b-form-radio :value="5">5</b-form-radio>
                        <b-form-radio :value="10">10</b-form-radio>
                        <b-form-radio :value="20">20</b-form-radio>
                        <b-form-radio :value="50">50</b-form-radio>
                        <b-form-radio :value="100">100</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>
        </div>
        <div class="k-flex-row">
            <div class="k-flex-row">
                Par état :
                <template v-for="tag in tags">
                    <BuildTag
                        :key="tag"
                        :tag-key="tag"
                        :currState="currState"
                        @click="currState = tag"
                    />
                </template>
            </div>
        </div>
        <b-table
            class="text-break mt-2 text-center"
            :items="dataProvider"
            :fields="fields"
            :current-page="currentPage"
            :filter="filter"
            :per-page="perPage"
            sort-by="updatedAt"
            :sort-desc="true"
            show-empty
            empty-text="Aucune génération"
            empty-filtered-text="Aucune génération trouvée"
            stacked="md"
            hover
            tbody-tr-class="pointer"
            @row-clicked="onRowClicked"
            striped
        >
            <template #table-busy>
                <div class="text-center my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Chargement des données...</strong>
                </div>
            </template>
            <template #cell(updatedAt)="data">
                {{ data.item.updatedAt | momentSinceISO }}
            </template>
            <template #cell(status)="data">
                <b-badge :variant="data.item.status | statusToVariant">
                    {{ data.item.status | statusToLabel }}
                </b-badge>
            </template>
            <template #cell(infos)="data">
                <div class="k-flex-row justify-content-start">
                    <b class="text-kalkin-2">
                        <b-icon-folder2-open />
                        {{ getProjectName(data.item) }}
                    </b>
                    <b-icon-caret-right-fill class="text-kalkin-1" />
                    <span class="text-kalkin-2">
                        <b-icon-display />
                        {{ getConfigName(data.item) }}
                    </span>
                    <b-icon-caret-right-fill class="text-kalkin-1" />
                    <span class="">
                        <b-icon-bar-chart-steps />
                        {{ getArboName(data.item) }}
                    </span>
                    <b
                        :class="`flag:${data.item.ArboBuilder.langId} flag-xs`"
                    />
                    <b-icon-caret-right-fill class="text-kalkin-1" />
                    <span class="text-secondary">
                        <b-icon-camera />
                        {{ getSnapshotDesc(data.item) }}
                    </span>
                </div>
            </template>
        </b-table>
        <div>
            <b-pagination
                class="m-auto"
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="totalRows"
                align="center"
                size="sm"
                first-number
                last-number
            />
        </div>
    </div>
</template>

<script>
import BuildTag from "./BuildTag.vue";
import { get as _get } from "lodash";

export default {
    components: {
        BuildTag,
    },

    data: function() {
        return {
            tags: BuildTag.KEYS,
            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            currState: 42,
            fields: [
                {
                    key: "id",
                    label: "#",
                    sortable: true,
                    tdClass: "text-nowrap",
                },
                {
                    key: "infos",
                    label: "Informations",
                    tdClass: "text-left",
                    sortable: false,
                },
                {
                    key: "updatedAt",
                    label: "Date de génération",
                    sortable: true,
                    tdClass: "text-nowrap",
                },
                {
                    key: "status",
                    label: "Etat",
                    sortable: true,
                    tdClass: "text-nowrap",
                },
            ],
            search: this.$route.query.search || "",
        };
    },

    computed: {
        filter() {
            let filter = "";
            if (this.search) {
                filter += `id startsWith ${this.search}`;
            }
            if (this.currState != 42) {
                filter += `status eq ${this.currState}`;
            }
            return filter.length > 0 ? filter : undefined;
        },
    },

    watch: {
        async filter() {
            await this.fetchTotalRows();
        },
    },

    methods: {
        async fetchTotalRows() {
            this.totalRows = await this.$store.dispatch(
                "admin/countFSPArboBuilds",
                {
                    filter: this.filter,
                },
            );
        },

        async dataProvider(ctx) {
            const arbobuilds = await this.$store.dispatch(
                "admin/getFSPArboBuilds",
                {
                    filter: this.filter,
                    sortby: (ctx.sortDesc ? "-" : "") + ctx.sortBy,
                    limit: this.perPage,
                    offset: this.perPage * (this.currentPage - 1),
                },
            );
            return arbobuilds;
        },

        getProjectName(item) {
            return _get(
                item,
                "ArboBuilder.ArboSnapshot.ApplicationConfigArbo.ApplicationConfig.Project.name",
            );
        },

        getConfigName(item) {
            return _get(
                item,
                "ArboBuilder.ArboSnapshot.ApplicationConfigArbo.ApplicationConfig.name",
            );
        },

        getArboName(item) {
            return _get(
                item,
                "ArboBuilder.ArboSnapshot.ApplicationConfigArbo.Arbo.name",
            );
        },

        getSnapshotDesc(item) {
            return _get(item, "ArboBuilder.ArboSnapshot.description");
        },

        onRowClicked(build) {
            this.$emit("item-clicked", build);
        },
    },

    async beforeMount() {
        await this.fetchTotalRows();
    },
};
</script>
