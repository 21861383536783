<template>
    <b-modal
        id="ModalArboBuild"
        size="xl"
        :title="`Détails de la génération #${build.id}`"
        header-bg-variant="kalkin-1"
        header-text-variant="light"
        hide-footer
        scrollable
    >
        <div class="k-card-1 k-flex-row">
            <div>
                Espace occupé sur le disque :
                <b class="text-kalkin-1" v-if="details.filesize">
                    {{ details.filesize | humanFileSize }}
                </b>
                <b class="text-kalkin-1" v-else>
                    inconnu
                </b>
            </div>
            <div>
                <b-button
                    size="xs"
                    class="mr-1"
                    variant="outline-kalkin-1"
                    @click="goToProject(getProjectId(build))"
                >
                    <b-icon-arrow-right-circle /> Aller au projet
                </b-button>
                <b-button
                    size="xs"
                    class="mr-1"
                    variant="outline-kalkin-1"
                    @click="goToConfig(getProjectId(build), getConfigId(build))"
                >
                    <b-icon-arrow-right-circle /> Aller a la configuration
                </b-button>
            </div>
        </div>

        <div v-if="details.logs">
            <h5 class="my-3">Journal de génération</h5>
            <template v-for="(event, idx) in details.logs.data">
                <ItemBuildEvent
                    :key="idx"
                    :event="event"
                    :next="details.logs.data[idx + 1]"
                />
            </template>
        </div>
        <div class="my-3 text-center" v-else>
            Aucun journal de génération
        </div>
    </b-modal>
</template>
<script>
import ItemBuildEvent from "./ItemBuildEvent";
import { get as _get } from "lodash";

export default {
    components: {
        ItemBuildEvent,
    },

    props: {
        build: { type: [Object], required: true },
    },

    data: function() {
        return {
            details: {},
        };
    },

    watch: {
        async build(value) {
            if (value.id) {
                await this.fetchBuildDetails();
            }
        },
    },

    methods: {
        async fetchBuildDetails() {
            this.details = await this.$store.dispatch("admin/getArboBuild", {
                id: this.build.id,
            });
        },

        getConfigId(item) {
            return _get(
                item,
                "ArboBuilder.ArboSnapshot.ApplicationConfigArbo.ApplicationConfig.id",
            );
        },

        getProjectId(item) {
            return _get(
                item,
                "ArboBuilder.ArboSnapshot.ApplicationConfigArbo.ApplicationConfig.Project.id",
            );
        },

        goToProject(projectId) {
            this.$router.push({
                name: "AdminProject",
                params: {
                    projectId: projectId,
                },
            });
        },

        goToConfig(projectId, configId) {
            this.$router.push({
                name: "AdminApplicationConfig",
                params: {
                    projectId: projectId,
                    configId: configId,
                },
            });
        },
    },

    async beforeMount() {
        if (this.build.id) {
            await this.fetchBuildDetails();
        }
    },
};
</script>
