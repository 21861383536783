<template>
    <b-badge
        class="pointer"
        :class="{
            isSelected: currState == tag.key,
            [`border-${tag.style}`]: true,
        }"
        :variant="tag.style"
        :key="tag.key"
        :title="tag.help"
        v-b-tooltip.hover.bottom.v-secondary
        @click="onClick(tag.key)"
    >
        {{ tag.label }}
    </b-badge>
</template>

<script>
const TAGS = [
    {
        key: "42",
        label: "Tous",
        style: "dark",
        help: "Toutes les générations",
    },
    {
        key: "0",
        label: "Vierge",
        style: "dark",
        help: "Générations pas lancée",
    },
    {
        key: "1",
        label: "En cours",
        style: "secondary",
        help: "Générations en cours de lancement",
    },
    {
        key: "2",
        label: "Echec",
        style: "danger",
        help: "Les générations échouées",
    },
    {
        key: "3",
        label: "Terminée",
        style: "kalkin-2",
        help: "Générations terminées",
    },
    {
        key: "4",
        label: "A supprimer",
        style: "danger",
        help: "Générations a supprimer",
    },
    {
        key: "5",
        label: "Partielle",
        style: "warning",
        help: "Générations partielles",
    },
];

const DEFAULT_TAG = {
    key: "unknown",
    label: "inconnu",
    style: "dark",
};

export default {
    props: {
        currState: [String, Number],
        tagKey: String,
    },

    KEYS: TAGS.map(t => t.key),

    data: function() {
        return {
            tags: TAGS,
        };
    },

    computed: {
        tag() {
            const tag = TAGS.find(t => t.key === this.tagKey);
            return tag || { ...DEFAULT_TAG, help: this.tagKey };
        },
    },

    methods: {
        onClick(payload) {
            this.$emit("click", payload);
        },
    },
};
</script>

<style lang="scss">
.isSelected {
    border: 4px solid;
}
</style>
