<template>
    <div class="k-page-flex">
        <div class="k-page-title">
            <h1>Historique de génération</h1>
        </div>
        <div class="k-page-body">
            <TableArboBuilds @item-clicked="onBuildSelected" />
        </div>

        <ModalArboBuild :build="selectedBuild" />
    </div>
</template>

<script>
import TableArboBuilds from "@/components/model/arbobuild/TableArboBuilds";
import ModalArboBuild from "@/components/model/arbobuild/ModalArboBuild";
export default {
    components: {
        TableArboBuilds,
        ModalArboBuild,
    },

    data: function() {
        return {
            selectedBuild: {},
        };
    },

    methods: {
        onBuildSelected(build) {
            this.selectedBuild = build;
            this.$bvModal.show("ModalArboBuild");
        },
    },
};
</script>
